import logo from './images/iscjEnvelopeLogo.png'
const Navbar = () => {
    return ( 
            <div id="container1" className="container">
                <div className="row">
                    <div className="col">
                        <div className="h1Block">
                            <h1 id="title" className="text-center">ISCJ Envelope Challenge!</h1>
                        </div>
                    </div>
                </div>
                <img id="imgLogo" src={logo} alt="logo" className='rounded mx-auto d-block'></img>
            </div>
     );
}
 
export default Navbar;