// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Envelope } = initSchema(schema);

export {
  Envelope
};