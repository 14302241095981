import './App.css';
import Navbar from './Navbar';
import Grid from './Grid';
// this is JSX. HTML style template + components, and Babble turns it into HTML to render.
// className is how to add classes to elements, and it's changed to class when rendered 
// react is imported in earlier versions of react like import React from 'react' but version 17 onward doesn't need it. 
function App() { // must start with a capital letter. App is first component that gets rendered. Top of the tree. 
  // variable can be array, number, whatever and it'll be rendered as a string by ReactDOM. Can't output booleans or objects
  return (
    <div className="App">
      <Navbar>
      </Navbar>
      <div className="content">
        <Grid >
        <style>{'body { background-color: #C4DBFA; }'}</style>
        </Grid>
      </div>
    </div>
  );
}

export default App;
// always export the component function. Usuing this in index.js. Rendered by the ReactDOM library. JSX template is above 