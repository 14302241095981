import { useEffect, useState } from 'react'; 
import { DataStore } from '@aws-amplify/datastore';
import { Envelope } from './models';
import ButtonList from './ButtonList';

const Grid = () => {
    // this works because users are only clicking one envelope at a time
    // and react will have currentEnvelope be for the user's selection; 
    // other users don't matter. Could run into race conditions 
    // so account for that w/r/t first donors, unless I use a last
    // updated by...or refresh the page...nah just use multiple first 
    // donors. 
    // const initial_models = DataStore.query(Envelope);
    const [currentEnvelope, setCurrentEnvelope] = useState(null); 
    const [envelopes, setEnvelopes] = useState(null);
    const [loading, setLoading] = useState(true);  
    // try using useCallback inside of this 
    async function fetchEnvelopes(){
        setLoading(true);
        const envelopesReturn = await DataStore.query(Envelope);
        // envelopesReturn.sort(getSortEnvelopes("value"));
        // asyncSort(envelopesReturn); 
        setEnvelopes(envelopesReturn);
        // asyncSort(); 
        // envelopes.sort(getSortEnvelopes("value")); 
        setLoading(false);
    }
    // const pullData = async () => {
    //     setLoading(true);
    //     const models = await DataStore.query(Envelope);
    //     // console.log(models);
    //     models.sort(getSortEnvelopes("value"));
    //     // setEnvelopes(models);
    //     // setLoading(false);
    //     return models; 
    // }
    const pullForRerender = async () => {
        setLoading(true);
        const models = await DataStore.query(Envelope);
        // console.log(models);
        models.sort(getSortEnvelopes("value"));
        setEnvelopes(models);
        //envelopes.sort(getSortEnvelopes("value")); 
        setLoading(false);
    }
    function getSortEnvelopes(fieldName){
        return function(env1, env2){
            return parseInt(env1[fieldName])>parseInt(env2[fieldName]);
        }
    }
    // const asyncSort = async () =>{
    //     // toSort.sort(getSortEnvelopes("value")); 
    // }
    // trying new useEffect 
    useEffect(() =>{
        fetchEnvelopes(); 
        //asyncSort();
        // envelopes.sort(getSortEnvelopes("value"));
        const subscription = DataStore.observe(Envelope).subscribe(() => fetchEnvelopes());
        // return () => subscription.unsubscribe();
    }, [])
    // best as of 4/11/2023
    // useEffect(() =>{
    //     pullData().then(res =>{
    //         return res; 
    //     }).then(data =>{
    //         // console.log('data is:', data); 
    //         setEnvelopes(data);
    //         setLoading(false);
    //     }).catch(err =>{
    //         console.log(err.message); 
    //     });
    //     //setEnvelopes(envs); 
    //     //refresh(); 
    // }, []);


    document.body.style="background-color: #a9c2f8";
    const handleBtnClick = (e, envelope) => {
        setCurrentEnvelope(envelope); 
        setLoading(false);
        // console.log("button clicked."); 
        // console.log(e.currentTarget);
        // e.currentTarget.className = "gridButtonClaimed btn m-1";
        // why don't I make a modal show up? Good idea
        // console.log(parseInt(e.target.value)); 
        document.getElementById("donorName").style.display="inline-block"; 
        document.getElementById("nameLabel").style.display="inline-block"; 
        document.getElementById("headMessage").innerHTML="Will you commit to donating this amount? This will mark the envelope as donated for everyone. &#13;&#10; &#13;&#10; To claim this envelope, click the 'Proceed to Donation' button anyway."; 
        const modal = document.getElementById('myModal'); 
        modal.style.display='block'; 
        const donorName = document.getElementById('donorName'); 
        donorName.value="Anonymous";
        document.getElementById("ayat").innerHTML=envelope.ayat;
        const donorMessage ="You have selected to donate $" + envelope.value + "."; 
        document.getElementById('donorMessage').innerHTML= donorMessage;
    }

    const handleSubmitClick = async () => {
        // USE CURRENT ENVELOPE HERE TO PROCESS IT  
        // or at least one way to do it :) 
        var modal = document.getElementById('myModal'); 
        // console.log(currentEnvelope); 
        modal.style.display="none"; 
        const claimPrompt = document.getElementById('claimPrompt');
        claimPrompt.style.display="none";
        window.open("https://us.mohid.co/nj/njrgn/iscj/masjid/online/donation/3", "_blank");
        // set the button used to be submitted.
        // update the envelope 
        // pull the current data at the end with the same function 
        if(currentEnvelope.claimed==="False"){
            pullForRerender(); // to see what the item.firstDonor is if someone claimed it already
            await DataStore.save(Envelope.copyOf(currentEnvelope, item => {
                // Update the values on {item} variable to update DataStore entry
                item.claimed="True"; 
                var currentName = currentEnvelope.donorName; 
                var newDonor = document.getElementById('donorName').value; 
                if(currentName == null){
                    item.firstDonor = newDonor; 
                } else{
                    var donorList = currentName + " + " + newDonor;
                    item.firstDonor = donorList; // for donor race conditions 
                }
            }));
            // now pull the data 
            // change the className of the button that was clicked on 
            const idToChange = "envelope" + currentEnvelope.value.toString(); 
            pullForRerender(); 
            //setLoading(false);
            document.getElementById(idToChange).classList = "gridButtonClaimed btn m-1"
        }
    }

    const handleCancelClick = () => {
        var modal = document.getElementById('myModal'); 
        modal.style.display="none"; 
        const claimPrompt = document.getElementById('claimPrompt');
        claimPrompt.style.display="none";
    }
    
    // this function will take the current envelope as input 
    // and tell the user who it was claimed by. The user 
    // can go to the website anyway 
    const handleClaimedBtnClick = (e, envelope) =>{
        setCurrentEnvelope(envelope); 
        const modal = document.getElementById('myModal'); 
        document.getElementById("ayat").innerHTML=envelope.ayat;
        modal.style.display='block'; 
        document.getElementById("donorName").style.display="none"; 
        document.getElementById("nameLabel").style.display="none"; 
        document.getElementById("headMessage").innerHTML="You can follow the link to donate again!"; 
        const claimPrompt = document.getElementById('claimPrompt');
        claimPrompt.style.display="block";
        claimPrompt.innerHTML="This envelope was already claimed by: " + envelope.firstDonor; 
        const donorMessage = "This envelope was already claimed. You can donate again!";
        document.getElementById('donorMessage').innerHTML= donorMessage;
    }

    return ( 
        <div className="container-fluid">
            <div id="myModal" className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 id="donorMessage">You are attempting to donate dollars.</h2>
                    </div>
                    <div className="modal-body">
                        <p id="ayat"></p>
                        <br></br>
                        <p id="headMessage">Will you commit to donating this amount? This will mark the envelope as donated for everyone. &#13;&#10; &#13;&#10; &nbsp; To claim this envelope, click the 'Proceed to Donation' button anyway.</p>
                        <p id="altPayment">You can also donate via Venmo or Zelle!  Leave a comment with your name + 'ISCJ Envelopes'. Leave out your name if you wish to remain anonymous.</p>
                        <p id="venmoZelle"><b>For Venmo, donate to @ISCJ-NJ and for Zelle, donate to accounting@iscj.org.</b></p>
                        <label id="nameLabel">Please enter your name to claim this envelope (or remain anonymous):&nbsp;</label>
                        <div className="row">
                        <input type="text" id="donorName" name="donorName" size="40" required={false} />
                        </div>
                        <br/>
                        <p id="claimPrompt">This has been claimed by:</p>
                        <div className="container my-3">
                            <div className="col-md-12 text-center">
                                <button id="submit" onClick={handleSubmitClick} className="btn btn-success m-3">Proceed to Donation</button>
                                <button id="cancel" onClick={handleCancelClick} className="btn btn-danger m-1">Close</button>
                            </div>
                        </div>
            
            
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>Welcome to the ISCJ Envelope Challenge! You can click on any of the envelopes below to 'claim' an envelope!</p> 
                    <p>To use Venmo or Zelle, comment with "ISCJ Envelopes" and your name in order to claim the envelope (don't add your name to remain anonymous).</p>
                    <p><b>When you click the "Proceed to Donate" button on the popup, you are COMMITTING to claiming that envelope so please be sure to donate!</b></p>
                    <p> It will be updated shortly after that! Please help support ISCJ insh'Allah!</p>
                    <p>Each envelope is for one chapter of the Glorious Qur'an. If everyone contributes even a small amount, that adds up to amazing results!</p>
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col d-flex justfiy-content-center">
                    <h2 className="placeHolder mx-auto">Select one of the remaining envelopes!</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center"> 
                    {/* {envelopes && <ButtonList buttons={envelopes} handleBtnClick={handleBtnClick} handleClaimedBtnClick = {handleClaimedBtnClick} />} */}
                    {/* {envelopes && <ButtonList buttons={envelopes} handleBtnClick={handleBtnClick} handleClaimedBtnClick = {handleClaimedBtnClick} />} */}
                    {loading && <div>Please wait...</div>}
                    {/* {envelopes.sort(getSortEnvelopes("value"))} */}
                    {envelopes && <ButtonList buttons={envelopes} handleBtnClick={handleBtnClick} handleClaimedBtnClick = {handleClaimedBtnClick} />}
                    {/* {loading ? <div>Please Wait...</div> : <ButtonList buttons={envelopes} handleBtnClick={handleBtnClick} handleClaimedBtnClick = {handleClaimedBtnClick} />} */}
                </div>
            </div>

        </div>
     );
}
 
export default Grid;