const ButtonList = ({buttons, handleBtnClick, handleClaimedBtnClick}) => {

    // function getSortEnvelopes(fieldName){
    //     return function(env1, env2){
    //         return parseInt(env1[fieldName])>parseInt(env2[fieldName]);
    //     }
    // }
    // async function actuallySort(toSort){
    //     await toSort.sort(getSortEnvelopes("value")); 
    // }
    // const buttonsArr = Object.values(buttons); 
    // buttonsArr.sort(getSortEnvelopes("value")); 
    // actuallySort(buttons); 
    // console.log(buttonsArr)
    const myData = [].concat(buttons).sort((a, b) => parseInt(a["value"]) > parseInt(b["value"]) ? 1:-1);
    return ( 
        <div className="buttonList">
            {/* <a href="https://www.iscjenvelopes.org" id="refreshMessage">In case you don't see anything, please click here REFRESH the page!</a> */}
            <br></br>
            {/* {console.log(buttons, "is buttons.")} */}
            {myData.map(envelope => {
                const id="envelope" + envelope.value; 
                if(envelope.claimed==="False"){
                    return <button id={id} onClick={(e) => handleBtnClick(e, envelope)} className="gridButton btn m-1" value={envelope.value} key={envelope.id}>
                        ${envelope.value}
                    </button>
                } else{
                return <button id={id} onClick={(e) => handleClaimedBtnClick(e, envelope)} className="gridButtonClaimed btn m-1" value={envelope.value} key={envelope.id}>
                    ${envelope.value}
                    </button>
                }
            })}
        </div>
     );
}
 
export default ButtonList;